<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-text-field solo placeholder="Client ou abonnement" clearable v-model="search.name" @click:append="filter()"
          append-icon="mdi-magnify" @keydown.enter="filter()" />

        <v-select solo :items="[-1, 0, 1, 2]" v-model="search.paymentStatus">
          <template v-slot:selection="data">
            <payment-status-chip v-if="data.item >= 0" :payment="{ status: data.item }" small />
            <v-chip v-else text-color="white" label small color="blue-grey" class="text-uppercase">{{ "Afficher tout"
              }}</v-chip>
          </template>
          <template v-slot:item="data">
            <payment-status-chip v-if="data.item >= 0" :payment="{ status: data.item }" small />
            <v-chip v-else text-color="white" label small color="blue-grey" class="text-uppercase">{{ "Afficher tout"
              }}</v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <div class="d-flex align-center align-stretch">
          <date-picker solo inner-icon :clearable="false" v-model="search.dateBegin" />
          <v-icon size="54" class="align-stretch">mdi-chevron-right</v-icon>
          <date-picker solo inner-icon :clearable="false" v-model="search.dateEnd" />
        </div>
        <div class="d-flex justify-space-between">
          <v-btn @click="filter()" x-large outlined color="primary">filtrer <v-icon right>mdi-magnify</v-icon></v-btn>
          
          <v-btn text color="green" x-large :loading="loadingXlsx" @click="exportXlsx">
            Excel
            <v-icon right>mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col>
        <v-data-table :loading="loading" :headers="headers" :items="payments" disable-sort>
          <template v-slot:item.clientName="{ item }">{{
            getClientName(item)
          }}</template>
          <template v-slot:item.paymentStatus="{ item }">
            <PaymentStatusChip :payment="item.payment" />
          </template>
          <template v-slot:item.paymentTypeName="{ item }">{{
            getPaymentTypeName(item)
          }}</template>
          <template v-slot:item.paymentDate="{ item }">{{
            $helpers.date.getFullDateDisplay(getPaymentDate(item))
          }}</template>
        </v-data-table>
      </v-col></v-row>
  </v-container>
</template>

<script>
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip";
import { saveAs } from "file-saver";
import DatePicker from "@/components/Admin/DatePicker.vue";

export default {
  name: "Payments",
  components: {
    PaymentStatusChip,
    DatePicker,
  },
  data() {
    return {
      loading: true,
      loadingXlsx: false,
      search: {
        dateBegin: this.$moment(new Date())
          .add({ month: -1 })
          .toDate()
          .toISOString()
          .substr(0, 10),
        dateEnd: new Date().toISOString().substr(0, 10),
        name: "",
        paymentStatus: -1,
      },

      headers: [
        {
          text: "Client",
          value: "clientName",
        },
        { text: "Abonnement", value: "membership_type.name" },
        { text: "Montant", value: "priceVatIncluded" },
        { text: "Statut", value: "paymentStatus", align: "center" },
        { text: "Date de paiement", value: "paymentDate" },
        { text: "Méthode", value: "paymentTypeName" },
      ],
      payments: [],
    };
  },
  computed: {},
  methods: {
    getPaymentDate(membership) {
      return membership.payment?.date;
    },
    getPaymentTypeName(membership) {
      return membership.payment?.payment_type?.name;
    },
    getClientName(membership) {
      return `${membership.client.firstName ?? ""} ${membership.client.lastName ?? ""
        } ${membership.client.companyName ?? ""}`.trim();
    },
    filter() {
      this.retrieveAll();
    },
    retrieveAll() {
      this.loading = true;
      this.$services.payment
        .getAll({ search: this.search })
        .then((response) => {
          this.payments = response.data;
          this.loading = false;
          console.dir(this.payments);
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("payments.error.retrieve");
          this.loading = false;
        });
    },
    async exportXlsx() {
      try {
        this.loadingXlsx = true;

        const doc = await this.$services.payment.export({
          format: "xlsx",
          search: this.search,
        });

        var byteCharacters = atob(doc.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "export.xlsx");
      } catch (e) {
        this.$helpers.snackbar.showErrorI18N(e);
      } finally {
        this.loadingXlsx = false;
      }
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>