import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"shrink"},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""},domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{attrs:{"small":""},domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c(VCol,{staticClass:"shrink"},[_c(VDivider,{attrs:{"vertical":""}})],1),_c(VCol,{},[_c('keep-alive',[_c(_vm.currentComponent,{tag:"component"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }