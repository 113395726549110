<template>
  <v-container>
    <v-row>
      <v-col><v-text-field solo placeholder="Client ou cours" clearable v-model="search.name" @click:append="filter()"
          append-icon="mdi-magnify" @keydown.enter="filter()" /></v-col>
      <v-col md="2"><date-picker solo inner-icon :clearable="false" v-model="search.dateBegin" />
      </v-col>
      <v-col class="shrink">
        <v-icon size="54">mdi-chevron-right</v-icon>
      </v-col>
      <v-col md="2">
        <date-picker solo inner-icon :clearable="false" v-model="search.dateEnd" />
      </v-col>
      <v-col class="shrink">
        <v-btn @click="filter()" x-large outlined color="primary">filtrer <v-icon right>mdi-magnify</v-icon></v-btn>
      </v-col>
      <v-col class="shrink">
        <v-btn text color="green" x-large :loading="loadingXlsx" @click="exportXlsx">
          Excel
          <v-icon right>mdi-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table :loading="loading" :headers="headers" :items="lessonMemberships" disable-sort>
          <template v-slot:item.planningDate="{ item }">{{
            $helpers.date.getDateDisplay(item.planningDateBegin)
          }}</template>
          <template v-slot:item.planningHour="{ item }">{{
            $helpers.date.getTimeStringFromDate(item.planningDateBegin)
          }}</template>
          <template v-slot:item.clientName="{ item }">{{
            getClientName(item)
          }}</template>
          <template v-slot:item.userName="{ item }">{{
            getUserName(item)
          }}</template>
          <template v-slot:item.membershipPaymentStatus="{ item }">
            <PaymentStatusChip :payment="{ status: item.membershipPaymentStatus }" />
          </template>
        </v-data-table>
      </v-col></v-row>
  </v-container>
</template>

<script>
import { saveAs } from "file-saver";
import DatePicker from "@/components/Admin/DatePicker.vue";
import PaymentStatusChip from "@/components/Admin/Payment/PaymentStatusChip";

export default {
  name: "LessonMemberships",
  components: {
    DatePicker,
    PaymentStatusChip,
  },
  data() {
    return {
      loading: true,
      loadingXlsx: false,
      search: {
        dateBegin: new Date().toISOString().substr(0, 10),
        dateEnd: this.$moment(new Date())
          // .add({ month: 1 })
          .toDate()
          .toISOString()
          .substr(0, 10),
        name: "",
      },

      headers: [
      { text: "Date", value: "planningDate" },
      { text: "Heure", value: "planningHour" },
        { text: "Cours", value: "lessonName" },
        {
          text: "Donné par",
          value: "userName",
        },
        {
          text: "Client",
          value: "clientName",
        },
        {
          text: "Abonnement",
          value: "membershipTypeName",
        },
        {
          text: "Unité",
          value: "planningMembershipUnit",
        },
        {
          text: "Paiement",
          value: "membershipPaymentTypeName",
        },
        {
          text: "Statut du paiement",
          value: "membershipPaymentStatus",
          align: "center",
        },
      ],
      lessonMemberships: [],
    };
  },
  computed: {},
  methods: {
    getUserName(data) {
      return `${data.userFirstName ?? ""} ${data.userLastName ?? ""}`.trim();
    },
    getClientName(data) {
      return `${data.clientFirstName ?? ""} ${data.clientLastName ?? ""
        } ${data.clientCompanyName ?? ""}`.trim();
    },
    filter() {
      this.retrieveAll();
    },
    retrieveAll() {
      this.loading = true;
      this.$services.report
        .getAllLessonMemberships({ search: this.search })
        .then((response) => {
          this.lessonMemberships = response.data;
          this.loading = false;
          console.log('lessonMemberships', this.lessonMemberships);
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("lessonMemberships.error.retrieve");
          this.loading = false;
        });
    },
    async exportXlsx() {
      try {
        this.loadingXlsx = true;

        const doc = await this.$services.report.exportAllLessonMemberships({
          format: "xlsx",
          search: this.search,
        });

        var byteCharacters = atob(doc.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "export.xlsx");
      } catch (e) {
        this.$helpers.snackbar.showErrorI18N(e);
      } finally {
        this.loadingXlsx = false;
      }
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>