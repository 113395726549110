<template>
  <v-container>
    <v-row>
      <v-col class="shrink">
        <v-list>
          <v-list-item-group v-model="tab" color="primary" mandatory>
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-icon>
                <v-icon v-text="item.icon" small></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" small></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab v-for="tag in tags" :key="tag">
            {{ tag }}
          </v-tab>
        </v-tabs> -->
        <!-- <v-chip-group column active-class="primary--text" v-model="tab">
          <v-chip v-for="tag in tags" :key="tag" :value="tag" large label>{{ tag }}</v-chip>
        </v-chip-group> -->
      </v-col>
      <v-col class="shrink">
        <v-divider
  vertical
></v-divider>
      </v-col>
      <v-col class="">
        <keep-alive>
          <component v-bind:is="currentComponent"></component>
        </keep-alive>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ReportPayments from "@/components/Admin/Report/ReportPayments";
import ReportLessonMemberships from "@/components/Admin/Report/ReportLessonMemberships";

export default {
  components: {
    ReportPayments,
    ReportLessonMemberships,
  },
  data() {
    return {
      tab: 0,
      items: [
        {
          icon: 'mdi-currency-eur',
          text: this.$helpers.string.capitalizeI18N("payments"),
        },
        // $helpers.string.capitalizeI18N("clients"),
        // $helpers.string.capitalizeI18N("memberships"),
        {
          icon: 'mdi-calendar-check',
          text: 'Présences',
        },
      ],
    };
  },
  computed: {
    currentComponent: function () {
      switch (this.tab) {
        case 0:
          return "ReportPayments";

        case 1:
          return "ReportLessonMemberships";

        default:
          return "ReportPayments";
      }
    },
  },
  watch: {
    tab: function (val) {
      const path = this.$route.path + "?tab=" + encodeURIComponent(val);

      if (this.$route.fullPath !== path) this.$router.push(path);
    },
  },
  methods: {},
  mounted() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>